export const dataMenu = [
  {
    title: "DETAILING & WRAPPING",
    slug: "/auto-detailing-car-wrapping",
  },
  {
    title: "O nas",
    slug: "/o-nas",
  },
  {
    title: "usługi i cennik",
    slug: "/uslugi-i-cennik",
  },
  {
    title: "nasze lokalizacje",
    slug: "/nasze-lokalizacje",
  },
  {
    title: "Strefa wiedzy",
    slug: "/strefa-wiedzy",
  },
  {
    title: "kontakt",
    slug: "/kontakt",
  },
];
