exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auto-detailing-car-wrapping-tsx": () => import("./../../../src/pages/auto-detailing-car-wrapping.tsx" /* webpackChunkName: "component---src-pages-auto-detailing-car-wrapping-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-nasze-lokalizacje-tsx": () => import("./../../../src/pages/nasze-lokalizacje.tsx" /* webpackChunkName: "component---src-pages-nasze-lokalizacje-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-strefa-wiedzy-co-to-jest-ozonowanie-samochodu-tsx": () => import("./../../../src/pages/strefa-wiedzy/co-to-jest-ozonowanie-samochodu.tsx" /* webpackChunkName: "component---src-pages-strefa-wiedzy-co-to-jest-ozonowanie-samochodu-tsx" */),
  "component---src-pages-strefa-wiedzy-jak-czesto-wykonywac-serwis-klimatyzacji-samochodowej-tsx": () => import("./../../../src/pages/strefa-wiedzy/jak-czesto-wykonywac-serwis-klimatyzacji-samochodowej.tsx" /* webpackChunkName: "component---src-pages-strefa-wiedzy-jak-czesto-wykonywac-serwis-klimatyzacji-samochodowej-tsx" */),
  "component---src-pages-strefa-wiedzy-jak-pozbyc-sie-nieprzyjemnych-zapachow-w-aucie-tsx": () => import("./../../../src/pages/strefa-wiedzy/jak-pozbyc-sie-nieprzyjemnych-zapachow-w-aucie.tsx" /* webpackChunkName: "component---src-pages-strefa-wiedzy-jak-pozbyc-sie-nieprzyjemnych-zapachow-w-aucie-tsx" */),
  "component---src-pages-strefa-wiedzy-jak-sprawdzic-jaki-czynnik-jest-w-aucie-tsx": () => import("./../../../src/pages/strefa-wiedzy/jak-sprawdzic-jaki-czynnik-jest-w-aucie.tsx" /* webpackChunkName: "component---src-pages-strefa-wiedzy-jak-sprawdzic-jaki-czynnik-jest-w-aucie-tsx" */),
  "component---src-pages-strefa-wiedzy-tsx": () => import("./../../../src/pages/strefa-wiedzy.tsx" /* webpackChunkName: "component---src-pages-strefa-wiedzy-tsx" */),
  "component---src-pages-uslugi-i-cennik-tsx": () => import("./../../../src/pages/uslugi-i-cennik.tsx" /* webpackChunkName: "component---src-pages-uslugi-i-cennik-tsx" */)
}

